// lib
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import { freeBagsReceived } from 'redux/actions/user';

// components
import CustomButton from 'components/UIKit/CustomButton';
import AdminInput from 'components/Admin/AdminInput';
import giftIcon from 'assets/images/recycletek-gift-icon.png';

// mui
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	main: {
		width: '100%',
		marginTop: '40px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
	},
	text: {
		width: '43%',
		'& h6': {
			marginBottom: '12px',
		},
	},
	buttonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-around',
		position: 'absolute',
		bottom: '10px',
	},
	icon: {
		height: '220px',
		width: 'auto',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
}));

export default function FreeEcoSacks({ closeOverlay, expressMode }) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const auth = useSelector((state) => state.auth);
	const [step, setStep] = useState(1);
	const [password, setPassword] = useState('');
	const [isButtonDisabled, setIsButtonDisabled] = useState();

	const exit = () => {
		!expressMode && history.push('/payment-select');
		expressMode && closeOverlay();
	};

	const cancel = () => {
		exit();
	};

	const handleFreeBagsReceived = () => {
		setIsButtonDisabled(true);
		dispatch(freeBagsReceived(password))
			.then(() => {
				exit();
			})
			.finally(() => {
				setIsButtonDisabled(false);
			});
	};

	const nextStep = () => {
		if (step === 1) {
			setStep((prevState) => prevState + 1);
		} else if (step === 2) {
			handleFreeBagsReceived();
		}
	};

	// if enter is pressed on the keyboard
	const handleKeyDown = (event) => {
		if (event.keyCode === 13) {
			handleFreeBagsReceived();
		}
	};

	// safeguard if a user knows how to access the route manually and can trick operator into giving away more free bags
	if (!auth.isAuthenticated || auth.isGuest) {
		return <Redirect to={!expressMode ? '/payment-select' : '/express'} />;
	}

	return (
		<>
			<Box className={classes.main}>
				{step === 1 && (
					<>
						<Box className={classes.text}>
							<Typography variant='h6'>{t('promotion.congratulations')}</Typography>
							<Typography variant='body2'>
								<Trans>{t('promotion.youAreEligibleEcoSacks')}</Trans>
							</Typography>
						</Box>
						<img className={classes.icon} src={giftIcon} alt='ecosack bag' />
					</>
				)}
				{step === 2 && (
					<>
						<Box className={classes.text}>
							<Typography variant='h6'>{t('promotion.enterEmployeePassword')}</Typography>
							<Typography variant='body2'>
								<Trans>{t('promotion.onceTheOperatorArrives')}</Trans>
							</Typography>
						</Box>
						<AdminInput
							label={t('admin.employeePassword')}
							value={password}
							isPassword={true}
							handleChange={(e) => setPassword(e.target.value)}
							keyDown={handleKeyDown}
						/>
					</>
				)}
			</Box>
			<Box className={classes.buttonContainer}>
				<CustomButton variant='alternate' onClick={cancel}>
					{t('common.buttons.cancel')}
				</CustomButton>
				<CustomButton disabled={isButtonDisabled} onClick={nextStep}>
					{t('common.buttons.continue')}
				</CustomButton>
			</Box>
		</>
	);
}
