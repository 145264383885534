import {
	LOGIN_SUCCESS,
	SET_GUEST_EMAIL,
	LOGOUT,
	WALLET_BALANCE,
	SET_PAYMENT_TYPE,
	SET_PAYMENT_ID,
	GUEST_LOGIN,
	SET_LANGUAGE,
	SET_NEW_SIGNUP,
	SET_COMPLIANCE_INFO,
	SET_COMPLIANCE,
	SET_SIGNATURE,
	SET_PAYMENT_DATA,
	SET_EMPLOYEE,
	SET_RECEIVED_BAGS,
} from '../actions/types';
import i18next from 'i18next';

const initialState = {
	access_token: null,
	isAuthenticated: false,
	isGuest: false,
	isNewSignUp: false,
	isLoading: false,
	customer: {},
	employee: {
		token: null,
		username: '',
	},
	language: i18next.resolvedLanguage || 'en',
	wallet: null,
	paymentType: '',
	paymentId: '',
	paymentOptionId: '',
	compliance: {
		open: false,
		overOneHundredCA: '',
		signature: '',
	},
	paymentData: {},
};

export const auth = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_SUCCESS:
			return {
				...state,
				access_token: action.payload.access_token,
				isAuthenticated: true,
				isLoading: false,
				customer: {
					...action.payload.customer,
				},
			};
		case SET_GUEST_EMAIL:
			return {
				isAuthorized: true,
				guest_email: action.email,
			};
		case GUEST_LOGIN:
			return {
				...state,
				isGuest: true,
			};
		case SET_EMPLOYEE:
			return {
				...state,
				employee: {
					...state.employee,
					token: action.payload.token,
					username: action.payload.username,
				},
			};
		case SET_LANGUAGE:
			return {
				...state,
				language: JSON.parse(action.payload),
			};
		case SET_PAYMENT_DATA:
			return {
				...state,
				paymentData: action.payload,
			};
		case WALLET_BALANCE:
			return {
				...state,
				wallet: action.payload,
			};
		case SET_PAYMENT_TYPE:
			return {
				...state,
				paymentType: action.payload.type,
				paymentId: action.payload.id,
				paymentOptionId: action.payload.optionId,
			};
		case SET_PAYMENT_ID:
			return {
				...state,
				paymentId: action.payload,
			};
		case SET_NEW_SIGNUP:
			return {
				...state,
				isNewSignUp: true,
			};
		case SET_SIGNATURE:
			return {
				...state,
				compliance: {
					...state.compliance,
					signature: action.payload,
				},
			};
		case SET_COMPLIANCE:
			return {
				...state,
				compliance: {
					...state.compliance,
					open: action.payload,
				},
			};
		case SET_COMPLIANCE_INFO:
			return {
				...state,
				compliance: {
					...state.compliance,
					overOneHundredCA: action.payload,
				},
			};
		case SET_RECEIVED_BAGS:
			return {
				...state,
				customer: {
					...state.customer,
					received_free_bags: action.payload,
				},
			};
		case LOGOUT:
			// resets everything except a few important ones like the payment data
			return {
				...state,
				access_token: null,
				isAuthenticated: false,
				isGuest: false,
				isNewSignUp: false,
				isLoading: false,
				customer: {},
				language: i18next.resolvedLanguage || 'en',
				wallet: null,
				paymentType: '',
				paymentId: '',
				paymentOptionId: '',
				compliance: {
					open: false,
					overOneHundredCA: '',
					signature: '',
				},
			};
		default:
			return state;
	}
};
