// lib
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { v4 as uuid } from 'uuid';

// mui
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// components
import { signUpUser, login } from 'redux/actions/auth';
import GuestAndSignUpButtons from 'components/GuestAndSignUpButtons';
import CustomNotification from 'components/UIKit/CustomNotification';
import PasswordValidation from 'pages/SignUp/PasswordValidation';
import { SET_NEW_SIGNUP } from 'redux/actions/types';
import CustomErrorBadge from 'components/UIKit/CustomErrorBadge';
import PortalTextField from 'components/UIKit/PortalTextField';
import RecycletekLogo from 'components/Background/RecycletekLogo';

// utils
import { screenEmailAndPhone } from 'utils/validation';

const useStyles = makeStyles((theme) => ({
	root: {
		height: '101vh',
		overflow: 'hidden',
	},
	image: {
		backgroundImage: 'url(/medBlueLogo.svg)',
		backgroundRepeat: 'no-repeat',
		backgroundColor: '#004F71',
		backgroundSize: '158% 162%',
		maxHeight: '100vh',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	inputPassword: {
		'-webkit-text-security': 'disc',
	},
	heading: {
		color: theme.palette.text.secondary,
		margin: '32px',
		'& .MuiTypography-root': {
			margin: '0px 0px 10px 10px',
			color: theme.palette.text.secondary,
		},
	},
	signUpHeading: {
		textAlign: 'center',
		marginBottom: '16px',
	},
	spinner: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '7%',
	},
	inputs: {
		'& .MuiDivider-root': {
			marginTop: '20px',
			marginBottom: '10px',
		},
	},
}));

// TODO: make the right side of page with the button group a components
export default function SignUp() {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const uniqueId = uuid().slice(0, 8);
	const modal = useSelector((state) => state.modal);
	const language = useSelector((state) => state.auth.language);
	const auth = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loadingKeyboard, setLoadingKeyboard] = useState(true);
	const [showPasswordText, setShowPasswordText] = useState(false);
	const [errMsg, setErrMsg] = useState('');

	useEffect(() => {
		if (modal.heading) {
			setOpen(true);
		}
	}, [modal]);

	useEffect(() => {
		if (loadingKeyboard) {
			// Keyboard.init();
			// Keyboard.open();
		}
		setLoadingKeyboard(false);
	}, []);

	const closeMessage = () => {
		setOpen(false);
	};

	const submitData = async () => {
		const formattedPhone = phone.replace(/[- )(]/g, '');
		const formattedEmail = email.trim();

		const phoneAndEmail = await screenEmailAndPhone(formattedEmail, formattedPhone);

		if (phoneAndEmail.ok) {
			setLoading(true);
			closeMessage(); // backup, need to look into why error message sometimes still appears

			dispatch(signUpUser(formattedEmail, formattedPhone, password))
				.then(() => {
					setLoading(false);
					dispatch(login(formattedEmail || formattedPhone));
					dispatch({
						type: SET_NEW_SIGNUP,
					});
				})
				.catch(() => {
					setLoading(false);
					setPassword('');
					setConfirmPassword('');
				});
		} else if (!phoneAndEmail.ok) {
			setErrMsg(t(phoneAndEmail.message));
		}
	};

	const handleSubmit = () => {
		const passwordRequirements = new RegExp(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\])(?=.{8,})/,
		);
		const validPassword = passwordRequirements.test(password);
		const allFields = (email || phone) && password && confirmPassword;
		const match = password === confirmPassword;

		closeMessage();

		if (!allFields) {
			return setErrMsg(t('messages.error.emptyFields'));
		} else if (!validPassword) {
			return setErrMsg(t('messages.error.passwordRequirements'));
		} else if (!match) {
			return setErrMsg(t('messages.error.passwordMatch'));
		} else if (allFields && validPassword && match) {
			return submitData();
		} else {
			return setErrMsg(t('messages.error.couldNotCreateAccount'));
		}
	};

	const textFieldClick = () => {
		// TODO: onclick field might be necessary for the keyboard,
		// see if this empty function can be removed
		// if (isKeyboardHidden) Keyboard.open();
	};

	// formats to (###) ###-####
	const formatPhoneNumber = (input) => {
		input = input.replace(/\D/g, '').substring(0, 10); //Strip everything but 1st 10 digits
		var size = input.length;
		if (size > 0) {
			input = '(' + input;
		}
		if (size > 3) {
			input = input.slice(0, 4) + ') ' + input.slice(4);
		}
		if (size > 6) {
			input = input.slice(0, 9) + '-' + input.slice(9);
		}
		setPhone(input);
	};

	const handlePasswordVisibility = () => {
		setShowPasswordText(!showPasswordText);
	};

	const clearInput = () => {
		setEmail('');
	};

	if (auth.isAuthenticated) {
		return <Redirect to={'/promotion'} />;
	}

	return (
		<Grid container component='main' className={classes.root}>
			<CustomNotification
				open={open}
				closeMessage={closeMessage}
				heading={modal.heading}
				message={modal.message}
			/>
			<Grid item xs={6} sm={7} md={7} className={classes.logo} square>
				<div>
					<RecycletekLogo />
					<Typography variant='h3' className={classes.signUpHeading}>
						{t('signUp.memberSignUp')}
					</Typography>

					{!loading && (
						<form className={classes.form}>
							<Typography variant='body2'>{t('signUp.phoneOrEmail')}</Typography>
							<Box className={classes.inputs}>
								<PortalTextField
									id={uniqueId}
									name='data-cy-signup-email'
									className={classes.inputText}
									placeholder={t('signUp.email')}
									fullWidth
									clearInput={clearInput}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									onClick={textFieldClick}
								/>
								<PortalTextField
									id={uniqueId}
									className={classes.inputText}
									placeholder={t('signUp.phone')}
									fullWidth
									clearInput={clearInput}
									value={phone}
									onChange={(e) => formatPhoneNumber(e.target.value)}
									onClick={textFieldClick}
								/>
								<Divider />
								<PortalTextField
									id={uniqueId}
									className={!showPasswordText && classes.inputPassword}
									passwordInput
									name='data-cy-signup-password'
									placeholder={t('signUp.password')}
									handlePasswordVisibility={handlePasswordVisibility}
									isPasswordVisible={showPasswordText}
									fullWidth
									onChange={(e) => setPassword(e.target.value)}
									onClick={textFieldClick}
								/>
								<PortalTextField
									id={uniqueId}
									className={!showPasswordText && classes.inputPassword}
									placeholder={t('signUp.confirmPassword')}
									name='data-cy-signup-confirm-password'
									passwordInput
									fullWidth
									onChange={(e) => setConfirmPassword(e.target.value)}
									handlePasswordVisibility={handlePasswordVisibility}
									isPasswordVisible={showPasswordText}
									onClick={textFieldClick}
								/>
								<PasswordValidation
									input={password}
									confirmPasswordInput={confirmPassword}
									includeSubmit
									handleSubmit={handleSubmit}
								/>
								<CustomErrorBadge errorMessage={errMsg} />
							</Box>
						</form>
					)}
					{loading && (
						<Box className={classes.spinner}>
							<CircularProgress />
						</Box>
					)}
				</div>
			</Grid>
			<Grid container item xs={6} sm={5} md={5} className={classes.image}>
				<Box className={classes.heading}>
					<Typography variant='h2'>{t('messages.main.welcome')}</Typography>
					<Trans>
						<Typography variant='subtitle1'>{t('messages.main.makingAChange')}</Typography>
					</Trans>
				</Box>
				<GuestAndSignUpButtons />
			</Grid>
		</Grid>
	);
}
