// lib
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

// mui
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Typography } from '@material-ui/core';

// components
import CustomButton from 'components/UIKit/CustomButton';
import WeightLimitInfo from 'pages/Express/WeightLimitInfo';

const useStyles = makeStyles((theme) => ({
	content: {
		display: 'flex',
		padding: '20px 10px',
		flexDirection: 'column',
		minHeight: '400px',
		minWidth: '300px',
		maxWidth: '300px',
		'& .MuiTypography-root': {
			fontSize: '18px',
		},
		'& .MuiTypography-body2': {
			marginTop: '4px',
			marginBottom: '20px',
		},
	},
	button: {
		position: 'relative',
	},
	icon: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px',
	},
	weights: {
		'& .MuiTypography-body2': {
			marginTop: 'unset',
			marginBottom: 'unset',
		},
	},
}));

export default function ExpressInfoMenu({ customer, paymentType }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const location = useSelector((state) => state.admin.state);
	const overweight = useSelector((state) => state.process.dailyWeightLimits.overweight);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<Box className={classes.button}>
				<CustomButton
					variant='secondary'
					size='extra-small'
					onClick={handleClick}
					highPriorityBadge={overweight.length > 0}
				>
					<Box className={classes.icon}>
						<InfoOutlinedIcon fontSize='large' />
					</Box>
				</CustomButton>
			</Box>

			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<Box className={classes.content}>
					<Box>
						<Typography variant='caption'>{t('express.customer')}</Typography>
						<Typography variant='body2'>{customer.first_name || customer.email}</Typography>
					</Box>
					<Box>
						<Typography variant='caption'>Payment Method:</Typography>
						<Typography variant='body2'>{paymentType}</Typography>
					</Box>
					<Box className={classes.weights}>{location === 'CA' && <WeightLimitInfo />}</Box>
				</Box>
			</Popover>
		</>
	);
}
