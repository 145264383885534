import axios from 'axios';
import * as Sentry from '@sentry/browser';
import {
	SET_DETECTED,
	MODAL_PRINT_RECEIPT_ERROR,
	MODAL_NO_ITEMS_DETECTED,
	GET_TASK_STATS,
	TASK_END,
	FAIL_PROCESS,
	TASK_GENERATED,
	MODAL_START_ERROR,
	SET_PREV_TASK_ID,
	MODAL_GENERAL_ERROR,
	SET_READY_TO_STOP,
	SET_REDEMPTION_AMOUNT,
	SET_DAILY_WEIGHT_LIMITS,
	TRANSACTION_FINALIZED,
	RESET_ECOCOUNT_ADJUSTMENTS,
	SET_CURRENT_MATERIALS,
} from 'redux/actions/types';
import { SERVER_URL } from 'redux/actions/config';
import { configHeader, employeeConfigHeader } from 'utils/configHeader';
import { getCurrentMaterials, addEcoCountAndAdjustments } from 'utils/totalContainers';
import { store } from 'redux/storage';
import i18next from 'i18next';

// creates a ledger entry
export const finalize = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader();
		const process = getState().process;
		const userInfo = getState().auth.compliance.overOneHundredCA;
		const signature = getState().auth.compliance.signature;

		const payload = {
			task_id: process.task_id?.task_id || process.prevTaskId,
			additional_info_type: userInfo?.type,
			additional_info_number: userInfo?.number,
			additional_info_state: userInfo?.state,
			additional_info_signature: signature,
		};

		const body = JSON.stringify(payload);

		axios
			.post(`${SERVER_URL}/task/finalize`, body, config)
			.then((res) => {
				dispatch({ type: TRANSACTION_FINALIZED });
				resolve();
			})
			.catch((err) => {
				const errorInfo = err.response.data.msg;
				dispatch({
					type: MODAL_GENERAL_ERROR,
					payload: {
						message: i18next.t('messages.modal.finalizeError'),
						info: `${errorInfo ? errorInfo : err}`,
						heading: i18next.t('messages.modal.error'),
					},
				});
				reject(err);
			});
	});
};

export function isTaskReady() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const config = employeeConfigHeader();
			const process = store.getState().process;

			const payload = {
				belt_id: process.throwError ? 'throw-error' : process.machine.belt_id,
				dryrun: process.dryrun,
			};
			const body = JSON.stringify(payload);

			axios
				.post(`${SERVER_URL}/task/is_ready`, body, config)
				.then((res) => {
					resolve(
						dispatch({
							type: SET_DETECTED,
							payload: true,
						}),
					);
				})
				.catch((err) => {
					const errorInfo = err.response.data.message;
					dispatch({
						type: MODAL_NO_ITEMS_DETECTED,
						payload: {
							message: i18next.t('messages.modal.failedToStart'),
							info: `${errorInfo ? errorInfo : err}`,
							heading: i18next.t('messages.modal.error'),
						},
					});
					reject();
				});
		});
	};
}

const setDailyWeightLimits = (res, dispatch) => {
	const overLimitData = res.data.over_limit;
	const withinTenPercentData = res.data.within_10_percent;
	const withinTenPercentMaterials = [];
	const overweightMaterials = [];

	overLimitData.forEach((material) => overweightMaterials.push(Object.keys(material)[0]));
	withinTenPercentData.forEach((material) => withinTenPercentMaterials.push(Object.keys(material)[0]));

	dispatch({
		type: SET_DAILY_WEIGHT_LIMITS,
		payload: {
			overweight: overweightMaterials,
			withinTenPercent: withinTenPercentMaterials,
			data: res.data,
		},
	});
};

export const taskStarted = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader();
		const process = getState().process;
		const auth = getState().auth;
		let payload = {
			customer_id: auth.customer.id,
			belt_id: process.machine.belt_id,
			secret: process.machine.secret,
			tasktype_id: '1',
			paymentoptions_id: auth.paymentId,
			donee_id: Number(auth.paymentOptionId),
			cashoutoption_id: auth.paymentOptionId,
			dryrun: process.dryrun,
			material: process.materialToLoad,
		};

		// this is turned on or off from the settings panel, used for testing
		if (process.throwError) {
			payload = { customer_id: 'throw-error', dryrun: process.dryrun };
		}

		const body = JSON.stringify(payload);
		axios
			.post(`${SERVER_URL}/task/start`, body, config)
			.then((res) => {
				dispatch({
					type: TASK_GENERATED,
					payload: res.data,
				});

				Sentry.setTag('task.id', res.data.task_id);

				resolve();
			})
			.catch((err) => {
				const errorInfo = err.response.data.message;

				dispatch({
					type: TASK_GENERATED,
					payload: false,
				});
				dispatch({
					type: MODAL_START_ERROR,
					payload: {
						message: i18next.t('messages.modal.failedToStart'),
						info: `${errorInfo ? errorInfo : err}`,
						heading: i18next.t('messages.modal.error'),
					},
				});
				reject();
			});
	});
};

export const getTaskStats = (redemptionAmountOnly) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = configHeader();
		const process = getState().process;
		const payload = {
			task_id: process.task_id?.task_id || process.prevTaskId,
			dryrun: process.dryrun,
		};
		const body = JSON.stringify(payload);

		axios
			.post(`${SERVER_URL}/task/get_task_stats`, body, config)
			.then((res) => {
				const { ready_to_stop } = res.data;

				if (redemptionAmountOnly) {
					dispatch({
						type: SET_REDEMPTION_AMOUNT,
						payload: res.data.redemption_amount_cents,
					});
					resolve();
				} else if (!redemptionAmountOnly) {
					setDailyWeightLimits(res, dispatch);
					dispatch({
						type: GET_TASK_STATS,
						payload: { ready_to_stop, data: res.data },
					});
					resolve(res.data);
				}
			})
			.catch((err) => {
				dispatch({
					type: FAIL_PROCESS,
					payload: false,
				});
			});
	});
};

export const stopProcess = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader();
		const process = getState().process;
		const payload = {
			task_id: process.task_id?.task_id,
			dryrun: process.dryrun,
		};
		const body = JSON.stringify(payload);

		// formats materials to list ones that are used in the task/transaction eg. '[PETE, ALU, LDPE]'
		const totalCounts = addEcoCountAndAdjustments();
		const currentMaterials = getCurrentMaterials(totalCounts);

		dispatch({
			type: SET_CURRENT_MATERIALS,
			payload: currentMaterials,
		});

		axios
			.post(`${SERVER_URL}/task/end`, body, config)
			.then((res) => {
				dispatch({
					type: TASK_END,
					payload: process.task_id?.task_id,
				});

				Sentry.setTag('task.ended.', true);

				resolve();
			})
			.catch((err) => {
				reject();
			});
	});
};

// updates ledger
export const manuallyInsertCounts = (weights) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader();
		const adjustments = getState().adjustments.materials;
		const scrap = getState().adjustments.scrap;
		const ecoCountScrap = getState().adjustments.ecoCountScrap;
		const process = getState().process;
		const auth = getState().auth;
		const payload = {
			tasktype_id: '1',
			customer_id: auth.customer.id,
			paymentoptions_id: auth.paymentId,
			for_donee_id: auth.paymentOptionId,
			belt_id: process.machine.belt_id,
			num_alu_lt_24oz: adjustments.ALU.sm,
			num_alu_geq_24oz: adjustments.ALU.md,
			num_bimetal_lt_24oz: adjustments.BMT.sm,
			num_bimetal_geq_24oz: adjustments.BMT.md,
			num_glass_lt_24oz: adjustments.GLS.sm,
			num_glass_geq_24oz: adjustments.GLS.md,
			num_plastic_pete_lt_24oz: adjustments.PETE.sm,
			num_plastic_pete_geq_24oz: adjustments.PETE.md,
			num_plastic_hdpe_lt_24oz: adjustments.HDPE.sm,
			num_plastic_hdpe_geq_24oz: adjustments.HDPE.md,
			num_plastic_pvc_lt_24oz: adjustments.PVC.sm,
			num_plastic_pvc_geq_24oz: adjustments.PVC.md,
			num_plastic_ldpe_lt_24oz: adjustments.LDPE.sm,
			num_plastic_ldpe_geq_24oz: adjustments.LDPE.md,
			num_plastic_pp_lt_24oz: adjustments.PP.sm,
			num_plastic_pp_geq_24oz: adjustments.PP.md,
			num_plastic_ps_lt_24oz: adjustments.PS.sm,
			num_plastic_ps_geq_24oz: adjustments.PS.md,
			num_plastic_other_lt_24oz: adjustments.OTHER.sm,
			num_plastic_other_geq_24oz: adjustments.OTHER.md,
			num_bag_in_box: adjustments.BIB.md,
			num_multilayer_pouch: adjustments.MLP.md,
			num_paperboard_carton: adjustments.PBC.md,
			weight_plastic_pete_lbs: weights.PETE || 0,
			weight_plastic_hdpe_lbs: weights.HDPE || 0,
			weight_plastic_pvc_lbs: weights.PVC || 0,
			weight_plastic_ldpe_lbs: weights.LDPE || 0,
			weight_plastic_pp_lbs: weights.PP || 0,
			weight_plastic_ps_lbs: weights.PS || 0,
			weight_plastic_other_lbs: weights.OTHER || 0,
			weight_glass_lbs: weights.GLS || 0,
			weight_alu_lbs: weights.ALU || 0,
			weight_bimetal_lbs: weights.BMT || 0,
			weight_bag_in_box_lbs: weights.BIB || 0,
			weight_multilayer_pouch_lbs: weights.MLP || 0,
			weight_paperboard_carton_lbs: weights.PBC || 0,
			scrap: {
				pete: scrap.PETE.concat(ecoCountScrap.pete || []),
				hdpe: scrap.HDPE.concat(ecoCountScrap.hdpe || []),
				pvc: scrap.PVC.concat(ecoCountScrap.pvc || []),
				ldpe: scrap.LDPE.concat(ecoCountScrap.ldpe || []),
				pp: scrap.PP.concat(ecoCountScrap.pp || []),
				ps: scrap.PS.concat(ecoCountScrap.ps || []),
				other: scrap.OTHER.concat(ecoCountScrap.other || []),
				glass: scrap.GLS.concat(ecoCountScrap.glass || []),
				aluminum: scrap.ALU.concat(ecoCountScrap.aluminum || []),
				'bi-metal': scrap.BMT.concat(ecoCountScrap['bi-metal'] || []),
			},
		};

		const body = JSON.stringify(payload);

		// formats materials to list ones that are used in the task/transaction eg. '[PETE, ALU, LDPE]'
		const currentMaterials = getCurrentMaterials(adjustments, weights, scrap);

		dispatch({
			type: SET_CURRENT_MATERIALS,
			payload: currentMaterials,
		});

		axios
			.post(`${SERVER_URL}/task/insert_fake_task`, body, config)
			.then((res) => {
				dispatch({
					type: SET_PREV_TASK_ID,
					payload: res.data.task_id,
				});
				dispatch({ type: RESET_ECOCOUNT_ADJUSTMENTS });

				Sentry.setTag('task.id', res.data.task_id);

				resolve();
			})
			.catch((err) => {
				const errorInfo = err.response.data.message;
				dispatch({
					type: MODAL_GENERAL_ERROR,
					payload: {
						message: i18next.t('messages.modal.errorAdjustingCounts'),
						info: `${errorInfo ? errorInfo : err}`,
						heading: i18next.t('messages.modal.error'),
					},
				});
				reject();
			});
	});
};

export const manuallyChangeCounts = (totalCounts, taskId, password, weights) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader();
		const process = getState().process;
		const scrap = getState().adjustments.scrap;
		const ecoCountScrap = getState().adjustments.ecoCountScrap;
		const customer = getState().auth.customer;
		const payload = {
			task_id: taskId,
			belt_id: process.machine.belt_id,
			password: password,
			num_alu_lt_24oz: totalCounts.ALU.sm,
			num_alu_geq_24oz: totalCounts.ALU.md,
			num_bimetal_lt_24oz: totalCounts.BMT.sm,
			num_bimetal_geq_24oz: totalCounts.BMT.md,
			num_glass_lt_24oz: totalCounts.GLS.sm,
			num_glass_geq_24oz: totalCounts.GLS.md,
			num_plastic_pete_lt_24oz: totalCounts.PETE.sm,
			num_plastic_pete_geq_24oz: totalCounts.PETE.md,
			num_plastic_hdpe_lt_24oz: totalCounts.HDPE.sm,
			num_plastic_hdpe_geq_24oz: totalCounts.HDPE.md,
			num_plastic_pvc_lt_24oz: totalCounts.PVC.sm,
			num_plastic_pvc_geq_24oz: totalCounts.PVC.md,
			num_plastic_ldpe_lt_24oz: totalCounts.LDPE.sm,
			num_plastic_ldpe_geq_24oz: totalCounts.LDPE.md,
			num_plastic_pp_lt_24oz: totalCounts.PP.sm,
			num_plastic_pp_geq_24oz: totalCounts.PP.md,
			num_plastic_ps_lt_24oz: totalCounts.PS.sm,
			num_plastic_ps_geq_24oz: totalCounts.PS.md,
			num_plastic_other_lt_24oz: totalCounts.OTHER.sm,
			num_plastic_other_geq_24oz: totalCounts.OTHER.md,
			num_glass_lt_24oz: totalCounts.GLS.sm,
			num_glass_geq_24oz: totalCounts.GLS.md,
			num_bag_in_box: totalCounts.BIB.md,
			num_multilayer_pouch: totalCounts.MLP.md,
			num_paperboard_carton: totalCounts.PBC.md,
			weight_plastic_pete_lbs: weights.PETE || 0,
			weight_plastic_hdpe_lbs: weights.HDPE || 0,
			weight_plastic_pvc_lbs: weights.PVC || 0,
			weight_plastic_ldpe_lbs: weights.LDPE || 0,
			weight_plastic_pp_lbs: weights.PP || 0,
			weight_plastic_ps_lbs: weights.PS || 0,
			weight_plastic_other_lbs: weights.OTHER || 0,
			weight_glass_lbs: weights.GLS || 0,
			weight_alu_lbs: weights.ALU || 0,
			weight_bimetal_lbs: weights.BMT || 0,
			weight_bag_in_box_lbs: weights.BIB || 0,
			weight_multilayer_pouch_lbs: weights.MLP || 0,
			weight_paperboard_carton_lbs: weights.PBC || 0,
			scrap: {
				pete: scrap.PETE.concat(ecoCountScrap.pete || []),
				hdpe: scrap.HDPE.concat(ecoCountScrap.hdpe || []),
				pvc: scrap.PVC.concat(ecoCountScrap.pvc || []),
				ldpe: scrap.LDPE.concat(ecoCountScrap.ldpe || []),
				pp: scrap.PP.concat(ecoCountScrap.pp || []),
				ps: scrap.PS.concat(ecoCountScrap.ps || []),
				other: scrap.OTHER.concat(ecoCountScrap.other || []),
				glass: scrap.GLS.concat(ecoCountScrap.glass || []),
				aluminum: scrap.ALU.concat(ecoCountScrap.aluminum || []),
				'bi-metal': scrap.BMT.concat(ecoCountScrap['bi-metal'] || []),
			},
		};
		const body = JSON.stringify(payload);

		// formats materials to list ones that are used in the task/transaction eg. '[PETE, ALU, LDPE]'
		const currentMaterials = getCurrentMaterials(totalCounts, weights, scrap);

		dispatch({
			type: SET_CURRENT_MATERIALS,
			payload: currentMaterials,
		});

		axios
			.post(`${SERVER_URL}/task/change`, body, config)
			.then(() => {
				dispatch({ type: RESET_ECOCOUNT_ADJUSTMENTS });
				resolve();
			})
			.catch((err) => {
				const errorInfo = err.response.data.message;
				dispatch({
					type: MODAL_GENERAL_ERROR,
					payload: {
						message: i18next.t('messages.modal.errorAdjustingCounts'),
						info: `${errorInfo ? errorInfo : err}`,
						heading: i18next.t('messages.modal.error'),
					},
				});
				reject();
			});
	});
};

export function pauseTask() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const config = employeeConfigHeader();
			const taskId = getState().process.task_id?.task_id;
			const dryrun = getState().process.dryrun;
			const payload = {
				task_id: taskId,
				dryrun,
			};
			const body = JSON.stringify(payload);

			axios
				.post(`${SERVER_URL}/task/pause`, body, config)
				.then((res) => {
					dispatch({
						type: SET_READY_TO_STOP,
						payload: false,
					});
					resolve();
				})
				.catch((err) => reject(err));
		});
	};
}

export const resumeTask = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = employeeConfigHeader();
		const taskId = getState().process.task_id?.task_id;
		const dryrun = getState().process.dryrun;
		const material = getState().process.materialToLoad;

		const payload = {
			task_id: taskId,
			dryrun,
			material,
		};

		const body = JSON.stringify(payload);
		axios
			.post(`${SERVER_URL}/task/resume`, body, config)
			.then((res) => {
				resolve();
			})
			.catch((err) => {
				const errorInfo = err.response.data.message;
				dispatch({
					type: MODAL_START_ERROR,
					payload: {
						message: i18next.t('messages.modal.failedToStart'),
						info: `${errorInfo ? errorInfo : err}`,
						heading: i18next.t('messages.modal.error'),
					},
				});
				reject();
			});
	});
};

export function printReceipt() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const config = employeeConfigHeader();
			const process = getState().process;
			const payload = {
				task_id: process.throwError ? 'throw-error' : process.prevTaskId,
			};
			const body = JSON.stringify(payload);

			axios
				.post(`${SERVER_URL}/task/print_receipt`, body, config)
				.then((res) => {
					resolve();
				})
				.catch((err) => {
					dispatch({
						type: MODAL_PRINT_RECEIPT_ERROR,
						payload: {
							message: i18next.t('messages.modal.couldNotPrintReceipt'),
							heading: i18next.t('messages.modal.error'),
						},
					});
					reject();
				});
		});
	};
}

export const emailReceipt = () => (dispatch, getState) => {
	const config = employeeConfigHeader();
	const process = getState().process;
	const payload = {
		task_id: process.throwError ? 'throw-error' : process.prevTaskId,
	};
	const body = JSON.stringify(payload);

	axios.post(`${SERVER_URL}/task/email_receipt`, body, config).catch((err) => {
		console.log(`Email Receipt ${err}`);
	});
};

export const getDailyWeightLimits = () => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		const config = configHeader();

		axios
			.get(`${SERVER_URL}/task/daily_weight_limits`, config)
			.then((res) => {
				setDailyWeightLimits(res, dispatch);
				resolve();
			})
			.catch((err) => {
				console.log(err);
				reject();
			});
	});
};

