import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// mui
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiTypography-caption': {
			marginBottom: '20px',
			marginTop: '4px',
		},
	},
	section: {
		marginTop: '20px',
	},
	materialContainer: {
		padding: '2px 4px',
		color: theme.palette.text.secondary,
		borderRadius: '4px',
		textAlign: 'center',
		fontWeight: '300',
		margin: '3px',
	},
	overweight: {
		backgroundColor: theme.palette.error.main,
	},
	almostOverweight: {
		backgroundColor: '#FFC845',
	},
}));

export default function WeightLimitInfo() {
	const classes = useStyles();
	const { t } = useTranslation();
	const limits = useSelector((state) => state.process.dailyWeightLimits);

	return (
		<Box className={classes.container}>
			<Box className={classes.section}>
				<Typography>{t('messages.weightLimits.overweight')}</Typography>
				{limits.overweight.length > 0 &&
					limits.data.over_limit.map((material) =>
						Object.entries(material).map((entry) => (
							<Box className={`${classes.materialContainer} ${classes.overweight}`}>
								<Typography>
									{entry[0]} {entry[1].toFixed(1)} lbs
								</Typography>
							</Box>
						)),
					)}
				{limits.overweight.length <= 0 && <Typography>None</Typography>}
			</Box>
			<Box className={classes.section}>
				<Typography>{t('messages.weightLimits.almostOverweight')}</Typography>
				{limits.withinTenPercent.length > 0 &&
					limits.data.within_10_percent.map((material) =>
						Object.entries(material).map((entry) => (
							<Box className={`${classes.materialContainer} ${classes.almostOverweight}`}>
								<Typography>
									{entry[0]} {entry[1].toFixed(1)} lbs
								</Typography>
							</Box>
						)),
					)}
				{limits.withinTenPercent.length <= 0 && <Typography>None</Typography>}
			</Box>
		</Box>
	);
}
