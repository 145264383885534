import axios from 'axios';
import { SERVER_URL } from 'redux/actions/config';
import { configHeader } from 'utils/configHeader';

export const isMobileUnique = async (phoneNumber) => {
	const config = configHeader();
	let isUnique = false;

	await axios
		.get(`${SERVER_URL}/customer/check_unique_phone/${phoneNumber}`, config)
		.then(() => {
			isUnique = true;
		})
		.catch(() => {
			isUnique = false;
		});
	return isUnique;
};

export const isUniqueEmail = async (email) => {
	const config = configHeader();
	let isUnique = false;

	await axios
		.get(`${SERVER_URL}/customer/check_unique/${email}`, config)
		.then(() => {
			isUnique = true;
		})
		.catch(() => {
			isUnique = false;
		});
	return isUnique;
};
