import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import CustomButton from 'components/UIKit/CustomButton';
import CustomSnackbar from 'components/UIKit/CustomSnackbar';
import { setPaymentType, updatePreferredPayment } from 'redux/actions/user';

const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		'& .MuiButton-root': {
			margin: '10px',
		},
	},
}));

export default function PaymentMethods({ taskEnded }) {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const customer = useSelector((state) => state.auth.customer);
	const forceEcoCardSetup = useSelector((state) => state.admin.forceEcoCardSetup);
	const paymentType = useSelector((state) => state.auth.paymentType);
	const payment = useSelector((state) => state.auth.paymentData);
	const cashPayoutEnabled = useSelector((state) => state.admin.expressCashEnabled);
	const [message, setMessage] = useState({ text: '', status: '' });
	const [showPreferred, setShowPreferred] = useState(false);

	const handleEcoCardSelected = () => {
		dispatch(
			setPaymentType(payment.keep.methods.card.name, payment.keep.methods.card.id, payment.keep.id),
		);
		setMessage({ text: t('express.ecoCardSelected'), status: 'success' });
	};

	const handleEcoWalletSelected = () => {
		dispatch(
			setPaymentType(
				payment.keep.methods.wallet.name,
				payment.keep.methods.wallet.id,
				payment.keep.id,
			),
		);
		setMessage({ text: t('express.ecoWalletSelected'), status: 'success' });
	};

	const handleCashSelected = () => {
		dispatch(
			setPaymentType(payment.keep.methods.cash.name, payment.keep.methods.cash.id, payment.keep.id),
		);
		setMessage({ text: 'Cash selected', status: 'success' });
	};

	const isEcoCardDisabled = () => {
		const ecoCardSetup = customer.onbe_account_number;

		if (!ecoCardSetup || taskEnded) {
			return true;
		} else {
			return false;
		}
	};

	const closeSnackbar = () => {
		setMessage({ text: '', status: '' });
	};

	const handleEcoCardSetup = () => {
		const pathname = '/payment-select';

		history.push({ pathname, state: { setupEcoCard: true } });
	};

	const handleShowPreferred = () => {
		setShowPreferred(true);
	};

	const setPreferredPayment = (selectedPayment) => {
		const paymentPreference = {
			paymentType: selectedPayment,
			paymentOption: payment.keep.id,
			cashoutOption: payment.keep.methods[selectedPayment]?.id,
		};

		dispatch(updatePreferredPayment(paymentPreference))
			.then(() => {
				setMessage({
					text: t('paymentSelect.preferredChanged', { payment: selectedPayment }),
					status: 'success',
				});
			})
			.catch(() => {
				setMessage({ text: t('paymentSelect.errorSettingPreferredPayment'), status: 'error' });
			});
	};

	return (
		<>
			<CustomSnackbar message={message.text} status={message.status} close={closeSnackbar} />
			{!showPreferred && (
				<Box className={classes.container}>
					<CustomButton
						variant={paymentType === 'card' ? 'primary' : 'alternate'}
						size='expanded'
						disabled={isEcoCardDisabled()}
						onClick={handleEcoCardSelected}
					>
						{t('express.ecoCardPayout')}
					</CustomButton>
					<CustomButton
						variant={paymentType === 'wallet' ? 'primary' : 'alternate'}
						size='expanded'
						disabled={taskEnded}
						onClick={handleEcoWalletSelected}
					>
						{t('express.ecoWalletPayout')}
					</CustomButton>
					{cashPayoutEnabled && (
						<CustomButton
							variant={paymentType === 'cash' ? 'primary' : 'alternate'}
							size='expanded'
							onClick={handleCashSelected}
						>
							{t('paymentSelect.cash')}
						</CustomButton>
					)}
					{(!customer.onbe_account_number || forceEcoCardSetup) && (
						<CustomButton
							variant={paymentType === 'card' ? 'primary' : 'alternate'}
							size='expanded'
							onClick={handleEcoCardSetup}
						>
							{t('paymentSelect.setupEcoCard')}
						</CustomButton>
					)}
					<CustomButton size='expanded' variant='alternate' onClick={handleShowPreferred}>
						{t('paymentSelect.setPreferred')}
					</CustomButton>
				</Box>
			)}
			{showPreferred && (
				<Box className={classes.container}>
					<CustomButton
						size='expanded'
						variant='alternate'
						disabled={isEcoCardDisabled()}
						onClick={() => setPreferredPayment('card')}
					>
						EcoCard
					</CustomButton>
					<CustomButton
						size='expanded'
						variant='alternate'
						name='wallet'
						onClick={() => setPreferredPayment('wallet')}
					>
						EcoWallet
					</CustomButton>
					<CustomButton
						size='expanded'
						variant='alternate'
						name='cash'
						onClick={() => setPreferredPayment('cash')}
					>
						Cash
					</CustomButton>
				</Box>
			)}
		</>
	);
}
